.auth-page {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    background-color: #ffff;
    flex-direction: row;
    justify-content: space-between;

    .auth-page-col1 {
        position: relative;
        width: 50%;
        height: 100%;
        padding: 0;
        margin: 0;
        border-radius: 0 20px 20px 0;
        background: #151313;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            // object-fit: cover;
        }
    }
    .forms{
        position: relative;
            display: flex;
            flex-direction: column;
            width: 50%;
            justify-content: center;
            align-items: center;    

            .dialog-button{
                position: absolute;
                top: 30px;
                right: 30px;
                border: none;
                border-radius: 50%;
                padding: 10px;
                cursor: pointer;
                transition: all 0.3s ease;
                background: -webkit-linear-gradient(left, #9747FF, #1671D9);
                &:hover{
                    background: -webkit-linear-gradient(left,#1671D9, #9747FF);
                }

            }
    }
}
@media only screen and (max-width: 768px) {
.auth-page {
        width: 100vw;
        height: 100vh;
        display: flex;
        background-color: #ffff;
        flex-direction: column;
        overflow-y: auto;
        .auth-page-col1 {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            background-color: black;
            border-radius: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .forms {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
            .dialog-button{
                position: absolute;
                top: 5px;
                right: 5px;
                border: none;
                padding: 5px;
                border-radius: 30%;
                cursor: pointer;
                transition: all 0.3s ease;
                background: -webkit-linear-gradient(left, #9747FF, #1671D9);
                &:hover{
                    background: -webkit-linear-gradient(left,#1671D9, #9747FF);
                }
            }
        }
    }
}