::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.layout {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
}
.side {
    width: 245px;
    transition: all 300ms ease;
    height: 100vh;
    z-index: 1;
    position: relative;
    border-right: 1px solid #E4E7EC;
    box-shadow: 12px 12px 24px rgba(221, 219, 219, 0.25);
    background-color: #FFFFFF;

}
.side.collapsed {
    width: 40px !important;
    transform: translateX(0px) !important;
}

.main {
    display: flex;
    flex-direction: column;
    width: calc(100% - 245px);
    background-color: #f9f9f9;

    
}
.mainFullWrap {
    width: calc(100%);
    // overflow: hidden;
    background-color: #fcfcfc;
   
}

.nav{
    height: 55px;
    width: 100%;
    box-shadow: 12px 12px 24px rgba(221, 219, 219, 0.25);
    z-index: 100;
    position: relative;
    background-color: white;
  
}

.child{
    // overflow: hidden;
    margin-top: 10px;
    height: calc(100vh - 75px);
    padding-left: 10px;
    overflow: auto;
    .breadcrumb {
            display: flex;
    
            .goBackBtn {
                margin-right: 7px;
                cursor: pointer;
    
                svg {
                    font-size: 17px;
                }
            }
    
            .single {
                display: flex;
    
                svg {
                    font-size: 17px;
                }
            }
    
            span,
            p {
                margin-right: 3px;
                cursor: pointer;
                font-size: 12px;
                cursor: pointer;
            }
        }
    
}

.foot{
    height: 20px;
    width: 100%;
    box-shadow: 12px 12px 24px rgba(221, 219, 219, 0.25);
    z-index: 100;
    position: relative;
    

}
.logo-button {
    display: flex;

}


li:hover {
    background-color: #E7E9F2;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 97%;
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.sidebar-link {
    display: flex;
    align-items: center;
    padding:  4px 4px 4px 10px;
    text-decoration: none;
    gap: 10px;
}

.collapse-icon {
    position: relative;
     margin: 55px 0px 0px 40px;
    z-index: 200;
}


.separator-label {
    color: #999;
    font-size: 12px;
    margin: 10px 0;
    padding: 0px 0px 0px 10px;
    font-weight: lighter;
}

.active-link {
    background: #E7E9F2;
    color: #101928;
}

.active-item{
    color: #101928 !important;
    font-weight: 600 !important;
}

.item-name {
    flex: 1 0 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #344054;
}

.sidebarCollapsedIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-top: 30px;
    flex-wrap: wrap;
   
}

.navbar-children {
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.collapsed-icon {
    position: relative;
    z-index: 200;
    margin: 0;
}


.navbar-content {
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 40px;
    padding: 0 28px 0 28px;
}
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
}

.logout-class{
    display: flex;
    flex-direction: row;
    position: absolute;
    padding: 4px;
    bottom: 1px;
    left: 3px;
    gap: 5px;
}